exports.components = {
  "component---src-pages-apparecchiature-js": () => import("./../../../src/pages/apparecchiature.js" /* webpackChunkName: "component---src-pages-apparecchiature-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-consigli-js": () => import("./../../../src/pages/consigli.js" /* webpackChunkName: "component---src-pages-consigli-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-convenzioni-js": () => import("./../../../src/pages/convenzioni.js" /* webpackChunkName: "component---src-pages-convenzioni-js" */),
  "component---src-pages-eventi-js": () => import("./../../../src/pages/eventi.js" /* webpackChunkName: "component---src-pages-eventi-js" */),
  "component---src-pages-family-card-js": () => import("./../../../src/pages/family-card.js" /* webpackChunkName: "component---src-pages-family-card-js" */),
  "component---src-pages-i-servizi-js": () => import("./../../../src/pages/i-servizi.js" /* webpackChunkName: "component---src-pages-i-servizi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partecipazioni-js": () => import("./../../../src/pages/partecipazioni.js" /* webpackChunkName: "component---src-pages-partecipazioni-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

